import { LeadBrandLogo } from '../context/leadBrand.context';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const fetchUrlFromCDN = (brand: string): Promise<LeadBrandLogo> => {
  const url = `${process.env.GATSBY_COLOR_LOGO_URL}${brand}`;
  return new Promise((resolve, reject) => {
    axiosRetry(axios, { retries: 1 });
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) resolve(response.data as LeadBrandLogo);
        else throw response;
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default fetchUrlFromCDN;
