import React, { useState } from 'react';

import StringUtils from '../utils/stringUtils';
import fetchUrlFromCDN from '../service/cdnService';
import queryString from 'query-string';

export const BrandLogoContext = React.createContext<{
  brandImages: LeadBrandLogo | undefined;
}>({
  brandImages: undefined,
});

export type LeadBrandLogo = {
  logo: string;
  brand: string;
};

const isBrowser = typeof document !== 'undefined';

export default function LeadBrandContextProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [brandImages, setBrandImages] = useState<LeadBrandLogo>();
  console.log('Browser Context');
  React.useEffect(
    () => {
      if (isBrowser) {
        const { leadBrand } = queryString.parse(document.location.search);
        let leadBrandText =
          leadBrand && !StringUtils.isQueryParamEmpty(leadBrand)
            ? Array.isArray(leadBrand) && leadBrand[0]
              ? leadBrand[0].toString()
              : leadBrand.toString()
            : '';

        fetchUrlFromCDN(leadBrandText)
          .then((response: LeadBrandLogo) => {
            'logo' in response && setBrandImages(response);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    },
    isBrowser ? [document.location.search] : []
  );

  return (
    <BrandLogoContext.Provider value={{ brandImages: brandImages }}>
      {children}
    </BrandLogoContext.Provider>
  );
}
