import isEmpty from 'lodash.isempty';

const StringUtils = {
  isQueryParamEmpty: (queryParamValue: string | (string | null)[] | null) => {
    if (
      isEmpty(queryParamValue) ||
      queryParamValue === '' ||
      queryParamValue === 'undefined' ||
      queryParamValue === 'null'
    )
      return true;
    return false;
  },
};

export default StringUtils;
